import Swiper from 'swiper';
import SwiperCore, { Navigation, Pagination } from 'swiper/core';
import 'simplebar';

import request from 'xhr-request';
import { parse } from 'node-html-parser';
import { on } from 'delegated-events';
import Header from './SalesForce/Header';

SwiperCore.use([Navigation, Pagination]);

new class HernoBlog {
    constructor() {
        const header = new Header();
        header.init().then(() => {
            this.load();
        }).catch(() => {
            this.load();
        });

    }

    debug() {
        const modules = [...document.querySelectorAll('[data-module]')];

        modules.forEach((module) => {
            module.innerHTML = module.innerHTML + '<div class="module-note">' + module.getAttribute('data-module') + '</div>';
        });
    }

    load() {
        document.getElementById('herno-loading').classList.add('in');

        this.bodyPadding();
        this.blog();
        this.swiper();
        this.fancybox();
        this.chess();
    }

    bodyPadding() {
        const header = document.querySelector('#header-container');

        function raf() {
            requestAnimationFrame(raf);

            if (header.contains(document.querySelector('.l-header_main'))) {
                document.querySelector('body').style.paddingTop = header.querySelector('.l-header_main').offsetHeight + 'px';
            }
        }

        raf();
    }

    blog() {
        if (document.querySelector('body').classList.contains('blog') || document.querySelector('body').classList.contains('archive')) {
            const grid = document.querySelector('.listing__grid');
            const loadmore = document.querySelector('.listing__more a');

            if (loadmore === null) {
                return;
            }

            loadmore.addEventListener('click', (e) => {
                e.preventDefault();

                request(e.currentTarget.getAttribute('href'), {
                    method: 'GET'
                }, function(err, data) {
                    if (err) throw err;
                    const newHtml = parse(data);
                    grid.insertAdjacentHTML('beforeend', newHtml.querySelector('.listing__grid').innerHTML);

                    if (newHtml.querySelector('.listing__more') !== null) {
                        loadmore.setAttribute('href', newHtml.querySelector('.listing__more a').getAttribute('href'));
                    } else {
                        document.querySelector('.listing__more').style.display = 'none';
                    }
                });
                setTimeout(() => {
                    loadmore.blur();
                }, 210);
            });
        }
    }

    swiper() {
        new Swiper('[data-swiper="default"]', {
            loop: true,
            pagination: {
                el: '.swiper-pagination'
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            }
        });


        const elements = [...document.querySelectorAll('[data-swiper="recent-posts"] .swiper-slide')]

        const loop = (elements.length > 2) ? true : false;

        if (loop) {
            new Swiper('[data-swiper="recent-posts"]', {
                slidesPerView: 1.2,
                spaceBetween: 30,
                loop: true,
                pagination: {
                    el: '.swiper-pagination'
                }
            });
        } else {
            new Swiper('[data-swiper="recent-posts"]', {
                slidesPerView: 1.2,
                spaceBetween: 30,
                loop: false
            });
        }
    }

    fancybox() {
        const fancybox = document.querySelector('#herno-fancybox');
        const prev = fancybox.querySelector('.herno-fancybox__prev');
        const next = fancybox.querySelector('.herno-fancybox__next');
        const bg = fancybox.querySelector('.herno-fancybox__bg');
        const inner = fancybox.querySelector('.herno-fancybox__inner');

        let elements = '';
        let pos = '';

        let isVideo = false;

        bg.addEventListener('click', (e) => {
            fancybox.classList.remove('in');
            setTimeout(() => {
                inner.innerHTML = '';
                isVideo = false;
            }, 200);
        });

        prev.addEventListener('click', (e) => {
            e.preventDefault();
            goTo('prev');
        });

        next.addEventListener('click', (e) => {
            e.preventDefault();
            goTo('next');
        });

        function goTo(dir) {
            if (dir === 'prev') {
                pos--;
            } else {
                pos++;
            }

            if (pos < 0) {
                pos = elements.length - 1;
            } else if (pos >= elements.length) {
                pos = 0;
            }

            setContent(elements[pos], true);
        }

        function setContent(element, animate) {
            const wait = animate ? 300 : 1;

            if (animate) {
                fancybox.classList.add('sliding');
            }

            const item = element.closest('.listing__item');

            setTimeout(() => {
                inner.innerHTML = '<img src="' + element.getAttribute('data-fancybox-image') + '" alt="" /><div><h3>' + item.querySelector('h3').innerHTML + '</h3><ul><li>' + item.querySelector('.date').innerHTML + '</li><li>' + item.querySelector('.nation').innerHTML + '</li></ul></div>';
            }, 200);

            if (animate) {
                setTimeout(() => {
                    fancybox.classList.remove('sliding');
                }, wait);
            }
        }

        on('click', '[data-fancybox-image]', function(e) {
            e.preventDefault();

            prev.style.display = 'block';
            next.style.display = 'block';
            inner.classList.remove('is-video');
            inner.classList.add('is-image');

            elements = [...document.querySelectorAll('[data-fancybox-image]')];
            const src = e.currentTarget.getAttribute('data-fancybox-image');

            let i = 0;
            elements.forEach((element) => {
                if (element.getAttribute('data-fancybox-image') === src) {
                    pos = i;
                }
                i++;
            });

            setContent(elements[pos], false);

            setTimeout(() => {
                fancybox.classList.add('in');
            }, 200);
        });

        on('click', '[data-fancybox-video]', function(e) {

            e.preventDefault();

            prev.style.display = 'none';
            next.style.display = 'none';
            inner.classList.remove('is-image');
            inner.classList.add('is-video');

            const videoId = e.currentTarget.getAttribute('data-fancybox-video');

            switch (e.currentTarget.getAttribute('data-fancybox-source')) {
                case 'vimeo':
                    inner.innerHTML = '<iframe src="https://player.vimeo.com/video/' + videoId + '?autoplay=1" width="640" height="480" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>';
                    break;

                case 'youtube':
                    inner.innerHTML = '<iframe width="560" height="315" src="https://www.youtube.com/embed/' + videoId + '?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
                    break;
            }

            isVideo = true;

            setTimeout(() => {
                fancybox.classList.add('in');
            }, 200);
        });

        function raf() {
            requestAnimationFrame(raf);

            if (!isVideo) {
                return;
            }

            const iframe = inner.querySelector('iframe');
            const ratio = 1.7777;

            iframe.style.width = inner.offsetWidth + 'px';
            iframe.style.height = (inner.offsetWidth / ratio) + 'px';
        }
        raf();
    }

    chess() {
        const elements = [...document.querySelectorAll('.chess')];

        function raf() {
            requestAnimationFrame(raf);

            elements.forEach((el) => {
                const text = el.querySelector('.chess__text');
                const media = el.querySelector('.chess__media');

                let height = 'auto';

                if (window.innerWidth > 991) {
                    height = media.querySelector('.chess__media__wrapper').offsetHeight + 'px';
                }

                text.style.height = height;
            });
        }

        raf();
    }
}();