import Globals from '../Globals';

const initSfccHeader = () => new Promise((resolve, reject) => {
	const headers = new Headers();

	if (Globals.externalHeader.username && Globals.externalHeader.password) {
		//headers.set('Authorization', `Basic ${btoa(`${Globals.externalHeader.username}:${Globals.externalHeader.password}`)}`);
	}

	const options = {
		credentials: 'include',
		withCredentials: true
	};

	fetch(Globals.externalHeader.url, options)
		.then((res) => res.text())
		.then((res) => {
			let count = 0;
			const div = document.createElement('div');
			div.innerHTML = res;

			Array.from(div.querySelectorAll('link')).forEach(function(link) {
				if (Globals.env === 'production') {
					return link.remove();
				} else {
					return document.head.appendChild(link);
				}
			});

			const headerContainer = document.querySelector('#header-container');
			const footerContainer = document.querySelector('#footer-container');
			headerContainer.innerHTML = div.querySelector('.l-header_wrap--external').innerHTML;
			footerContainer.innerHTML = div.querySelector('.l-footer_wrap--external').innerHTML;

			const ret = { headerContainer, footerContainer };

			Array.from(div.querySelectorAll('script')).forEach(function(oldScript) {
				var newScript = document.createElement('script');

				Array.from(oldScript.attributes).forEach((attr) => newScript.setAttribute(attr.name, attr.value));

				if (newScript.src) {
					const scriptLoader = () => {
						count--;
						if (count === 0) {
							resolve(ret);
							if ('init' in app && typeof app.init === 'function') {
								app.init();
							}
						}
					};

					count++;
					newScript.async = false;
					newScript.addEventListener('load', scriptLoader);
					newScript.addEventListener('error', scriptLoader);
				}

				newScript.appendChild(document.createTextNode(oldScript.innerHTML));
				document.head.appendChild(newScript);
				oldScript.remove();
			});
		})
		.catch((error) => {
			reject(error);
		});
});

const initEvents = () => {
	document.querySelectorAll('.js-language_selector_link').forEach((elm) => {
		const click = () => {
			const expires = new Date();
			var minutes = 1;
			expires.setTime(expires.getTime() + (minutes * 60 * 1000));
			document.cookie = `${Globals.newLangCookie}=TRUE; expires=${expires.toUTCString()}; domain=${Globals.siteDomain}; path=/`;
		};

		elm.addEventListener('click', click);
	});
};

class Header {
	init() {
		return initSfccHeader().then(initEvents);
	}
}

export default Header;
